@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import "./data/styles.css";

body {
	margin: 0;
	font-family: var(--primary-font);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: "DM Sans", sans-serif;
}
